import React, { FC } from 'react';
import Logo from 'common/assets/img/logo.png';
import * as S from './styles';
import { Link } from 'components';

export const Header: FC = () => {
  return (
    <S.HeaderRow>
      <img src={Logo} alt="Logo" />
      <Link href="mailto:support@viewercentric.com">CONTACT US</Link>
    </S.HeaderRow>
  );
};
