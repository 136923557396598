import React, { FC } from 'react';
import * as S from './styles';
import { Props } from './interfaces';

export const StatusItem: FC<Props> = ({ icon, title, dedline, color }) => {
  return (
    <S.Item color={color}>
      <S.Row>
        <img src={icon} alt="Icon" />
        <S.Title>{title}</S.Title>
      </S.Row>
      {dedline && <S.Dedline>{dedline}</S.Dedline>}
    </S.Item>
  );
};
