import styled from 'styled-components';
import Backgroung from 'common/assets/img/header.jpg';
import { primaryColors } from 'common/styles/theme';

export const Page = styled.section``;
export const Container = styled.div`
  max-width: 1025px;
  margin: auto;
  position: relative;
  min-height: 100vh;
`;

export const DashboardHeader = styled.header``;
export const DashboardHeaderDecoration = styled.div`
  background-image: url(${Backgroung});
  background-repeat: no-repeat;
  background-size: cover;
  height: 145px;
  position: absolute;
  width: 100%;
`;

export const DashboardIntro = styled.div`
  margin: 0 0 45px 0;
`;

export const DashboardIntroStatus = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #dcdde1;
  padding: 0 0 11px;
`;

export const CurrentStatus = styled.div`
  color: ${primaryColors.grey};
  font-size: 13px;
  span {
    color: ${primaryColors.black};
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusIndicator = styled.span`
  color: ${primaryColors.black};
  font-size: 11px;
  display: flex;
  align-items: center;
  margin: 0 0 0 20px;
  img {
    margin: 0 5px 0 0;
  }
`;

export const Link = styled.a`
  color: ${primaryColors.grey};
  text-decoration: none;
`;

export const Title = styled.h2`
  color: ${primaryColors.black};
  font-size: 25px;
  margin: 28px 0 15px 0;
`;

export const Subtitle = styled.p`
  color: ${primaryColors.grey};
  font-size: 13px;
  line-height: 18px;
`;

export const SubtitleStrong = styled.strong`
  color: ${primaryColors.black};
`;

export const SubtitleLink = styled.a`
  color: ${primaryColors.accent};
`;

export const StatusList = styled.div`
  color: ${primaryColors.accent};
`;
