import Error from 'common/assets/img/error.svg';
import { IStatusData } from './interfaces';

export const statusData = [
  {
    icon: Error,
    title: 'ViewerCentric Engagement Analytics API',
    dedline: 'Instagram follower counts are under-reported. Fix expected November 12, 2021',
    color: '#f16f6f1c',
  },
] as IStatusData[];
