import React from 'react';
import GlobalStyles from 'common/styles/global';
import { StatusDashboard } from 'pages/StatusDashboard';

function Bootstrap() {
  return (
    <>
      <StatusDashboard />
      <GlobalStyles />
    </>
  );
}

export default Bootstrap;
