import { createGlobalStyle } from 'styled-components';
import Fonts from '../assets/fonts';
import { font, primaryColors } from './theme';

export default createGlobalStyle`
  @font-face {
    font-family: 'Product Sans';
    src: local('Product Sans'),
    local('ProductSans-Regular'), url(${Fonts.ProductSans}) format('woff'),
    url(${Fonts.ProductSans2}) format('woff2');
    font-style: normal;
    font-display: swap;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: ${font.sizes.normal}; 
  }
  
  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: ${font.family.ProductSans};
    font-weight: ${font.normal};
    color: ${primaryColors.black};
    background-color: ${primaryColors.background};
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: ${font.normal};
  }
  

  button {
    cursor: pointer;
  }
  
  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
