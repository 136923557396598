import React, { FC } from 'react';
import Back from 'common/assets/img/back.svg';
import Success from 'common/assets/img/success.svg';
import Error from 'common/assets/img/error.svg';
import { Header, Card, StatusItem } from 'components';
import * as S from './styles';
import { statusData } from './statusData';
import { IStatusData } from './interfaces';

export const StatusDashboard: FC = () => {
  return (
    <S.Page>
      <S.DashboardHeaderDecoration />
      <S.Container>
        <S.DashboardHeader>
          <Header />
        </S.DashboardHeader>

        <Card>
          <>
            <S.DashboardIntro>
              <S.Link href="https://app.viewercentric.com/" target="_blank">
                <img src={Back} alt="Arrow Back" /> Back to ViewerCentric
              </S.Link>
              <S.Title>ViewerCentric App Status</S.Title>
              <S.Subtitle>
                This page is intended for <S.SubtitleStrong>ViewerCentric</S.SubtitleStrong> customers. If you are experiencing issues with
                end-user features and <br /> services, please contact{' '}
                <S.SubtitleLink href="mailto:support@viewercentric.com">customer support</S.SubtitleLink>.
              </S.Subtitle>
            </S.DashboardIntro>

            <S.DashboardIntroStatus>
              <S.CurrentStatus>
                Current Status - <span>Oct 27, 2021 PDT</span>{' '}
              </S.CurrentStatus>
              <S.Status>
                <S.StatusIndicator>
                  <img src={Success} alt="Success Icon" />
                  Operational
                </S.StatusIndicator>{' '}
                <S.StatusIndicator>
                  <img src={Error} alt="Error Icon" /> Service disruption
                </S.StatusIndicator>
              </S.Status>
            </S.DashboardIntroStatus>
            <S.StatusList>
              {statusData.length ? (
                statusData.map((status: IStatusData) => (
                  <StatusItem key={status.dedline} icon={status.icon} title={status.title} dedline={status?.dedline} color={status.color} />
                ))
              ) : (
                <StatusItem icon={Success} title="All Systems Operating Normally" color="#0fb4501f" />
              )}
            </S.StatusList>
          </>
        </Card>
      </S.Container>
    </S.Page>
  );
};
