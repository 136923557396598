export const primaryColors = {
  white: '#FFFFFF',
  black: '#191928',
  grey: '#6E7396',
  background: '#F5F5FA',
  accent: '#2948EB',
} as const;

export const secondaryColors = {
  red: '#F04164',
  green: '#0FB450',
} as const;

export const font = {
  light: 300,
  normal: 400,
  semiBold: 600,
  bold: 700,
  sizes: {
    normal: '14px',
    large: '1.8rem',
  },
  lineHeight: {
    base: '20px',
  },
  family: {
    ProductSans: 'Product Sans',
  },
} as const;

export const ZIndex = {
  base: 10,
} as const;

export const breakpoints = {
  small: '576px',
  medium: '768px',
  large: '992px',
} as const;
