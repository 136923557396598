import styled from 'styled-components';

export const Link = styled.a`
  background: none;
  border: 1px solid #ccc;
  border-radius: 14px;
  padding: 6px 10px;
  min-width: 70px;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
`;
